import * as React from "react";
import Header from "../components/Header";
import HeaderMenu from "../components/HeaderMenu";
import Section from "../components/Section";
import { withLayout, LayoutProps, menuItems } from "../components/Layout";
import Data from "../../content/home.json";

const IndexPage = (props: LayoutProps) => {
  return (
    <React.Fragment>
      {/* Master head */}
      <HeaderMenu pathname={props.location.pathname} items={menuItems} />
      <Header title={Data.title} strapline={Data.strapline} showSocial />

      <Section title={Data.projects.title} index={0} components={Data.projects.content} fullSize />
      <Section title={Data.experience.title} index={1} components={Data.experience.content} alt />
    </React.Fragment>
  );
};

export default withLayout(IndexPage);
